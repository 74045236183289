import { createContext } from 'react'
import { WalletState, initialWalletState } from './wallet-state'
import { Address, Hex } from 'viem'
import {
  GetLinkTransferResponse,
  TransferByLinkResponse
} from '../client/types'
import { CoinMovingHistoryEntity } from '../client/query/coin-moving'
import { OnetimeLockEntity } from '../client/query/onetime-lock'

export interface ExZeroContextInterface extends WalletState {
  createWallet: (projectName: string) => Promise<void>
  recoverWallet: () => Promise<void>
  updateNickName: (nickName: string) => Promise<void>
  approve: (token: Address) => Promise<void>
  transfer: (
    token: Address,
    target: Address,
    amount: bigint,
    message?: { message: string; imageType: string }
  ) => Promise<boolean>
  executeOperation: (target: Address, callData: Hex) => Promise<void>
  transferByLink: (
    token: Address,
    amount: bigint,
    metadata: any,
    expiration: number
  ) => Promise<TransferByLinkResponse | null>
  getLinkTransfer: (id: string) => Promise<GetLinkTransferResponse | null>
  receiveLinkTransfer: (
    linkTransfer: GetLinkTransferResponse,
    secret: Hex
  ) => Promise<boolean>
  loadHistory: (token: Address) => Promise<CoinMovingHistoryEntity[]>
  loadAllHistory: (token: Address) => Promise<CoinMovingHistoryEntity[]>
  loadOnetimeLockHistory: (token: Address) => Promise<OnetimeLockEntity[]>
  loadNicknames: (ethAddressList: Address[]) => Promise<void>
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <Auth0Provider>.')
}

export const initialContext = {
  ...initialWalletState,
  createWallet: stub,
  recoverWallet: stub,
  updateNickName: stub,
  approve: stub,
  transfer: stub,
  executeOperation: stub,
  transferByLink: stub,
  getLinkTransfer: stub,
  receiveLinkTransfer: stub,
  loadHistory: stub,
  loadAllHistory: stub,
  loadOnetimeLockHistory: stub,
  loadNicknames: stub
}

export const ExZeroContext =
  createContext<ExZeroContextInterface>(initialContext)
