import {
  Address,
  Hex,
  decodeAbiParameters,
  encodeAbiParameters,
  encodeFunctionData,
  erc20Abi,
  hashTypedData,
  keccak256,
  maxUint256
} from 'viem'
import {
  createMessageHash,
  generateLinkTransferSignature,
  signHash,
  signLinkTransfer
} from './sign'
import {
  ExZeroUser,
  GetLinkTransferResponse,
  TransferByLinkResponse
} from './types'
import {
  PERMIT2_MAP,
  TRANSFER_DISPATCHER_MAP,
  TransferRequest,
  TransferWithSecretRequest,
  UserOperation
} from '@exzero/js-sdk'
import { encodeOwner } from '@exzero/js-sdk/dist/datatypes/WebAuthnAuth'
import { getERC4337Nonce } from './nonce'
import { ExZeroApiService } from './api'
import { getPermit2Nonce } from '../../hooks/usePermit2Nonce'
import { CHAIN_ID } from '../../constants'
import { ethClient } from '../../utils/client'
import { readContract } from 'viem/actions'
import { getUserHandle, registerByWebAuthn } from './WebAuthnAuth'
import { SmartWalletAbi } from '../../abis/SmartWallet'
import {
  COIN_MOVING_ALL_HISTORY_QUERY,
  COIN_MOVING_HISTORY_QUERY,
  CoinMovingHistoriesEntity,
  CoinMovingHistoryEntity
} from './query/coin-moving'
import localForage from 'localforage'
import {
  browserSupportsWebAuthn,
  platformAuthenticatorIsAvailable
} from '@simplewebauthn/browser'
import {
  ONETIME_LOCK_QUERY,
  OnetimeLockEntity,
  OnetimeLocksEntity
} from './query/onetime-lock'

localForage.config({
  name: 'exzero',
  version: 1.0,
  storeName: 'exzero_address', // Should be alphanumeric, with underscores.
  description: 'address store'
})

export class ExZeroClient {
  user?: ExZeroUser
  apiService: ExZeroApiService

  constructor(
    chainId: number,
    apiKey: string,
    endpoint: string = 'https://api.prex0.com/api/wallet'
  ) {
    this.apiService = new ExZeroApiService(chainId, apiKey, endpoint)
  }

  async isPasskeyAvailable() {
    return (
      browserSupportsWebAuthn() && (await platformAuthenticatorIsAvailable())
    )
  }

  async createWallet(projectName: string) {
    const address = await localForage.getItem('address')

    if (address !== null) {
      return
    }

    const creationResult = await registerByWebAuthn(projectName)

    const owner = encodeOwner(
      creationResult.publicKey[0],
      creationResult.publicKey[1]
    )

    const registeredAddress = await this.apiService.getAddress(owner)

    const ok = await this.apiService.register(
      owner,
      creationResult.userHandler,
      creationResult.name
    )

    if (ok !== 'ok') {
      throw new Error('Failed to register')
    }

    console.log('register', registeredAddress.address)

    await localForage.setItem('address', registeredAddress.address)

    this.user = {
      id: creationResult.userHandler,
      name: creationResult.name,
      nickname: '',
      address: registeredAddress.address,
      nonce: 0n,
      noncePermit2: 0n
    }
  }

  async recoverWallet() {
    const userHandle = await getUserHandle()

    if (!userHandle) {
      throw new Error('Failed to get user handle')
    }

    const result = await this.apiService.getEthAddressByUserHandle(userHandle)

    if (!result.ethAddress) {
      throw new Error(`Failed to get eth address for ${userHandle}`)
    }

    await localForage.setItem('address', result.ethAddress)

    this.user = {
      id: result.id,
      name: result.name,
      nickname: '',
      address: result.ethAddress as Address,
      nonce: 0n,
      noncePermit2: 0n
    }
  }

  async updateNickName(nickName: string) {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    const timestamp = Math.floor(Date.now() / 1000) + 60 * 60

    const hash = keccak256(
      encodeAbiParameters(
        [
          { type: 'string' },
          { type: 'uint256' },
          { type: 'uint256' },
          { type: 'address' },
          { type: 'string' }
        ],
        [
          'update at exzero.com/api/nickname',
          BigInt(this.apiService.chainId),
          BigInt(timestamp),
          this.user.address,
          nickName
        ]
      )
    )

    const messageHash = createMessageHash(
      this.apiService.chainId,
      hash,
      this.user.address
    )

    const signature = await signHash(messageHash)

    await this.apiService.updateNickName(
      this.user!.address,
      nickName,
      signature,
      timestamp
    )

    this.user.nickname = nickName
  }

  async load() {
    console.log(
      'This app is built using the ExZero API. The ExZero API is a service that allows for easy development of apps using smart wallets. The service is planned to launch at https://www.prex0.com, so please stay tuned.'
    )

    const address = await localForage.getItem('address')

    if (address === null) {
      return
    }

    const nicknames = await this.apiService.getNickNames([address as Address])

    const nicknameFromServer = nicknames.length > 0 ? nicknames[0].nickname : ''

    this.user = {
      id: '',
      name: '',
      nickname: nicknameFromServer || '',
      address: address as Address,
      nonce: BigInt(0),
      noncePermit2: BigInt(0)
    }
  }

  async fetchNicknames(ethAddressList: Address[]) {
    if (ethAddressList.length === 0) {
      return []
    }

    const nicknames = await this.apiService.getNickNames(ethAddressList)

    return nicknames
  }

  async updateNonce() {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    const nonce = await getERC4337Nonce(this.user.address, BigInt(0))

    this.user.nonce = nonce
  }

  async transfer(
    token: Address,
    recipient: Address,
    amount: bigint,
    message?: {
      message: string
      imageType: string
    }
  ) {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    const messageId = crypto.randomUUID()

    const nonce = await getPermit2Nonce(
      this.user.address,
      PERMIT2_MAP[this.apiService.chainId]
    )

    const deadline = BigInt(Math.floor(Date.now() / 1000 + 24 * 60 * 60))

    const request = new TransferRequest(
      {
        dispatcher: TRANSFER_DISPATCHER_MAP[this.apiService.chainId],
        sender: this.user.address,
        deadline: deadline,
        nonce: nonce,
        amount: amount,
        token: token,
        recipient: recipient,
        metadata: message
          ? encodeAbiParameters([{ type: 'string' }], [messageId])
          : '0x'
      },
      this.apiService.chainId,
      PERMIT2_MAP[this.apiService.chainId]
    )

    const hash = this._getHashOfTransfer(request)

    const messageHash = createMessageHash(
      this.apiService.chainId,
      hash,
      this.user.address
    )

    const signature = await signHash(messageHash)

    const isValid = await ethClient.readContract({
      address: this.user.address,
      abi: SmartWalletAbi,
      functionName: 'isValidSignature',
      args: [hash, signature]
    })

    if (isValid === '0xffffffff') {
      throw new Error('Invalid signature')
    }

    const ok = await this.apiService.submit('transfer::submitTransfer', {
      encodedRequest: request.serialize(),
      sig: signature,
      message: message
        ? {
            messageId,
            message: message.message,
            imageType: message.imageType
          }
        : null
    })

    if (ok !== 'ok') {
      throw new Error('Failed to submit')
    }
  }

  _getHashOfTransfer(request: TransferRequest) {
    const { domain, types, message } = request.permitData()

    return hashTypedData({
      domain,
      types,
      message,
      primaryType: 'PermitWitnessTransferFrom'
    })
  }

  async transferByLink(
    token: Address,
    amount: bigint,
    metadata: any,
    expiration: number,
    isRequiredLock = true
  ): Promise<TransferByLinkResponse> {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    const nonce = await getPermit2Nonce(
      this.user.address,
      PERMIT2_MAP[this.apiService.chainId]
    )

    const { request, signature, secret } = await signLinkTransfer(
      this.apiService.chainId,
      token,
      this.user.address,
      amount,
      nonce,
      expiration
    )

    const id = TransferWithSecretRequest.parse(
      request,
      CHAIN_ID,
      PERMIT2_MAP[this.apiService.chainId]
    )
      .getRequestId()
      .toLowerCase()

    const ok = await this.apiService.submit(
      isRequiredLock
        ? 'onetimeLock::submitRequest'
        : 'transferWithSecret::createMessage',
      {
        encodedRequest: request,
        sig: signature,
        messageId: id,
        message: metadata.message,
        imageType: metadata.imageType
      }
    )

    if (ok !== 'ok') {
      throw new Error('Failed to submit')
    }

    // store secret
    await localForage.setItem('secret-' + id, secret)

    return {
      id,
      secret
    }
  }

  async getLinkTransfer(id: string) {
    const message = await this.apiService.getMessage(id)

    if (!message.messageId) {
      // not found

      return null
    }

    const getLinkTransferResponse = JSON.parse(message.messageBody)

    return {
      ...getLinkTransferResponse,
      transferType: message.messageFormat
    } as GetLinkTransferResponse
  }

  async receiveLinkTransfer(
    linkTransfer: GetLinkTransferResponse,
    secret: Hex
  ) {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    const request = TransferWithSecretRequest.parse(
      linkTransfer.request as Hex,
      CHAIN_ID,
      PERMIT2_MAP[this.apiService.chainId]
    )

    const recipientData = {
      recipient: this.user.address,
      sig: await generateLinkTransferSignature(
        secret,
        request.params.dispatcher,
        request.params.nonce,
        this.user.address
      ),
      metadata: '0x'
    }

    if (linkTransfer.transferType === 'onetimeLock') {
      await this.apiService.submit('onetimeLock::completeRequest', {
        id: request.getRequestId(),
        recipientData: recipientData
      })
    } else {
      await this.apiService.submit('transferWithSecret::submitRequest', {
        encodedRequest: request.serialize(),
        sig: linkTransfer.signature,
        recipientData: recipientData
      })
    }
  }

  async approve(token: Address) {
    await this.executeOperation(
      token,
      encodeFunctionData({
        abi: erc20Abi,
        functionName: 'approve',
        args: [PERMIT2_MAP[this.apiService.chainId], maxUint256]
      })
    )
  }

  async getBalance(token: Address) {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    return await readContract(ethClient, {
      address: token,
      abi: erc20Abi,
      functionName: 'balanceOf',
      args: [this.user.address]
    })
  }

  async getHistory(token: Address) {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    const result = await this.apiService.getHistory(
      'coinMovingHistories',
      COIN_MOVING_HISTORY_QUERY,
      { address: this.user.address.toLowerCase(), token: token.toLowerCase() }
    )

    const history = result.data as CoinMovingHistoriesEntity

    return history.coinMovingHistories.map(item => ({
      ...item,
      token: item.token.id,
      sender: item.sender.id,
      recipient: item.recipient.id,
      createdAt: Number(item.createdAt)
    })) as CoinMovingHistoryEntity[]
  }

  async getAllHistory(token: Address) {
    const result = await this.apiService.getHistory(
      'coinMovingHistories',
      COIN_MOVING_ALL_HISTORY_QUERY,
      { token: token.toLowerCase() }
    )

    const history = result.data as CoinMovingHistoriesEntity

    return history.coinMovingHistories.map(item => ({
      ...item,
      token: item.token.id,
      sender: item.sender.id,
      recipient: item.recipient.id,
      createdAt: Number(item.createdAt)
    })) as CoinMovingHistoryEntity[]
  }

  async getOnetimeLockHistory(token: Address) {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    const result = await this.apiService.getHistory(
      'onetimeLocks',
      ONETIME_LOCK_QUERY,
      { address: this.user.address.toLowerCase(), token: token.toLowerCase() }
    )

    const history = result.data as OnetimeLocksEntity

    const historyItems = history.onetimeLocks.map(async item => {
      // store secret
      const secret = await localForage.getItem<Hex>(
        'secret-' + item.id.toLowerCase()
      )

      let messageId: undefined | string = undefined

      try {
        if (item.metadata === '0x') {
          messageId = item.id
        } else {
          const decoded = decodeAbiParameters(
            [{ type: 'string' }],
            item.metadata as Hex
          )
          messageId = decoded[0]
        }
      } catch (e) {
        console.error(e)
      }

      return {
        ...item,
        token: item.token.id,
        sender: item.sender.id,
        recipient: item.recipient?.id,
        createdAt: Number(item.createdAt),
        updatedAt: Number(item.updatedAt),
        secret: secret,
        messageId
      } as OnetimeLockEntity
    })

    return Promise.all(historyItems)
  }

  async executeOperation(target: Address, callData: Hex) {
    if (!this.user) {
      throw new Error('User not initialized')
    }

    await this.updateNonce()

    const userOperation = UserOperation.createUserOperation(
      this.user.address,
      this.user.nonce,
      target,
      '0x', // initCode
      callData,
      BigInt(this.apiService.chainId),
      {
        maxFeePerGas: '0.31',
        maxPriorityFeePerGas: '0.17'
      }
    )

    const paymasterAndData = await this.apiService.signPaymasterAndData(
      userOperation
    )

    const newUserOperation = UserOperation.deserialize(
      userOperation.serialize(paymasterAndData),
      BigInt(this.apiService.chainId)
    )

    console.log(1, newUserOperation)

    const opHash = await newUserOperation.hash()

    console.log(2, opHash)

    const signature = await signHash(opHash)

    /*
    const messageHash = createMessageHash(this.apiService.chainId, opHash, this.user.address)

    const signature = await signHash(messageHash)

    const isValid = await ethClient.readContract({
      address: this.user.address,
      abi: SmartWalletAbi,
      functionName: 'isValidSignature',
      args: [opHash, signature]
    })

    console.log(isValid)

    if (isValid === '0xffffffff') {
      throw new Error('Invalid signature')
    }

    await ethClient.simulateContract({
      address: ENTRY_POINT_ADDRESS_MAP[this.apiService.chainId],
      abi: EntryPointAbi,
      functionName: 'simulateValidation',
      args: [{
        ...newUserOperation.params,
        signature: signature
      }]
    })
      */

    await this.apiService.execute(newUserOperation, signature)
  }

  getUser() {
    return this.user
  }
}
