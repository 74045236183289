import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryButton, SubButton } from '../components/Button'
import { LoadingIndicator, LoadingIndicatorDark } from './LoadingIndicator'
import useExZero from '../exzero/react/use-exzero'
import { PlatformOS, usePlatformOS } from '../hooks/usePlatformOS'
import icloudPng from '../assets/onboarding/icloud.png'
import { AccordionContainer } from './common/Accordion'
import * as QRCode from 'qrcode'
import { TransferWithSecretRequest } from '@exzero/js-sdk'
import { CHAIN_ID, PERMIT2_ADDRESS } from '../constants'
import { Hex, zeroHash } from 'viem'
import { Link } from 'wouter'
import { RequestStatus, useRequestStatus } from '../hooks/useRequest'
import { toErrorMessage } from '../utils/error'

const OnBoardingPasskey = ({ platform }: { platform: PlatformOS }) => {
  if (platform === PlatformOS.iOS) {
    return (
      <div className="mb-40">
        <div className="text-sm">
          パスキーを使うには、iOS 16、またはiPadOS 16以降が必要です。また、
          <a
            href="https://support.apple.com/ja-jp/guide/iphone/iph82d6721b2/17.0/ios/17.0"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            iCloudキーチェーン
          </a>
          と
          <a
            href="https://support.apple.com/ja-jp/guide/iphone/iphd709a3c46/17.0/ios/17.0"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            2ファクタ認証
          </a>
          もオンになっている必要があります。
        </div>
        <div className="my-6 text-sm">
          <AccordionContainer title="iCloudキーチェーンを設定するには？">
            <div>
              <div className="my-2">
                「設定」＞「[自分の名前]」＞「iCloud」＞「パスワードとキーチェーン」と選択してから「iCloudキーチェーン」をオンにし、画面に表示される指示に従います。
              </div>
              <img
                src={icloudPng}
                alt="パスワードとキーチェーン"
                className="w-[336px] rounded mx-auto"
              />
            </div>
          </AccordionContainer>
        </div>
      </div>
    )
  } else if (platform === PlatformOS.Mac) {
    return (
      <div className="text-sm">
        パスキーを使うには、macOS 13以降が必要です。また、
        <a
          href="https://support.apple.com/ja-jp/guide/mac-help/mh43699/14.0/mac/14.0"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          iCloudキーチェーン
        </a>
        を設定する必要があります。
      </div>
    )
  } else if (platform === PlatformOS.Android) {
    return (
      <div className="text-sm">
        詳しくは、
        <a
          href="https://support.google.com/android/answer/14124480?hl=ja"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          「パスキーでアプリやウェブサイトにログインする」
        </a>
        をご覧ください。
      </div>
    )
  } else {
    return (
      <div className="text-sm">
        申し訳ございません。お使いの環境では、awaCoinウォレットをご利用いただけません。
      </div>
    )
  }
}

const InnerReceiveComponent = () => {
  const params = new URLSearchParams(window.location.search)
  const [request, setRequest] = useState<TransferWithSecretRequest | null>(null)

  const [amount, setAmount] = React.useState<bigint | undefined>(undefined)
  const [isNotFound, setIsNotFound] = React.useState(false)

  const id = params.get('id')

  const requestStatus = useRequestStatus(
    CHAIN_ID,
    request ? request.getRequestId() : zeroHash
  )

  const [src, setSrc] = useState<string | null>(null)
  const { getLinkTransfer, loadNicknames } = useExZero()

  useEffect(() => {
    const url = location.href

    QRCode.toDataURL(url).then(image => {
      setSrc(image)
    })
  }, [setSrc])

  useEffect(() => {
    if (id) {
      getLinkTransfer(id).then(message => {
        if (message === null) {
          setIsNotFound(true)
          return
        }
        const request = TransferWithSecretRequest.parse(
          message.request as Hex,
          CHAIN_ID,
          PERMIT2_ADDRESS
        )

        setRequest(request)

        setAmount(request.params.amount)

        loadNicknames([request.params.sender])
      })
    }
  }, [id, getLinkTransfer, setRequest, setAmount, loadNicknames])

  if (isNotFound) {
    return (
      <div className="my-3">
        <div className="text-black">このリンクは見つかりません。</div>
        <div>
          <Link href="/" className="text-blue-600 underline">
            ホームに戻る。
          </Link>
        </div>
      </div>
    )
  }

  if (request === null || requestStatus.data === undefined) {
    return <LoadingIndicatorDark />
  }

  if (requestStatus.data !== RequestStatus.Pending) {
    return (
      <div className="my-3">
        <div className="text-black">このコインは、受け取り済みです。</div>
      </div>
    )
  }
  return (
    <div className="my-3">
      <div className="text-black">
        この二次元コードをスキャンしてもらうことで、もらったコインを渡すことができます。
      </div>
      <div className="mt-3 p-3 flex justify-around shadow text-black">
        <div className="mt-2 space-y-2">
          <div>
            有効期限：
            {request
              ? new Date(
                  Number(request.params.deadline) * 1000
                ).toLocaleDateString()
              : '...'}
          </div>
          <div>数量: {Number(amount)} awaCoin</div>
        </div>
        <div>
          {src ? (
            <img src={src} alt="qr code" className="w-40" />
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    </div>
  )
}

const RegisterWallet = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, isLoading } = useExZero()

  const [isCreatingWallet, setIsCreatingWallet] = useState<boolean>(false)

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingIndicatorDark />
      </div>
    )
  }

  if (isAuthenticated) {
    return <>{children}</>
  }

  return (
    <div className="w-full h-full p-4">
      {location.pathname === '/receive' ? (
        <div>
          <InnerReceiveComponent />
          <a
            onClick={() => {
              setIsCreatingWallet(!isCreatingWallet)
            }}
            className="flex justify-end text-blue-600 text-sm"
          >
            ウォレットを登録して、コインを入金する
          </a>
          {isCreatingWallet ? <RegisterWalletInner /> : null}
        </div>
      ) : (
        <RegisterWalletInner />
      )}
    </div>
  )
}

const RegisterWalletInner = () => {
  const { error, isPasskeyAvailable, isLoading, createWallet, recoverWallet } =
    useExZero()

  const onRegister = useCallback(async () => {
    await createWallet('awaCoinApp')
  }, [createWallet])

  const onRecover = useCallback(async () => {
    await recoverWallet()
  }, [recoverWallet])

  const platformOs = usePlatformOS()

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingIndicatorDark />
      </div>
    )
  }

  if (!isPasskeyAvailable) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <div className="p-2 text-zinc-600 text-sm">
          このデバイスでは、
          <br />
          パスキーがサポートされていません。
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-3">
      <div className="flex justify-center items-center">
        <div className="my-4 text-sm">
          <div className="text-base font-bold text-zinc-800">
            awaCoinウォレットを使用するには、端末にパスキーを登録する必要があります。
          </div>
          <div className="py-1 text-zinc-600">
            <OnBoardingPasskey platform={platformOs} />
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 pb-6 z-999 left-0 w-full p-4 space-y-2 bg-white">
        <div className="text-xs text-red-700">
          {error ? toErrorMessage(error) : null}
        </div>

        <PrimaryButton onClick={onRegister} size="lg">
          {isLoading ? <LoadingIndicator /> : '登録する'}
        </PrimaryButton>

        <SubButton onClick={onRecover}>
          {isLoading ? <LoadingIndicator /> : 'すでにウォレットを持っている'}
        </SubButton>
      </div>
    </div>
  )
}

export default RegisterWallet
