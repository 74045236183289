import React, { useCallback } from 'react'
import { PrimaryButton } from '../components/Button'
import { mint } from '../exzero/client/api'
import RegisterWallet from '../components/RegisterWallet'
import { AWC_ADDRESS } from '../constants'
import useExZero from '../exzero/react/use-exzero'

enum Status {
  NotReceived,
  Processing,
  Received
}

const DemoView = () => {
  const [status, setStatus] = React.useState<Status>(Status.NotReceived)
  const { user } = useExZero()

  const onReceive = useCallback(async () => {
    if (!user) {
      throw new Error('Failed to generate key')
    }

    setStatus(Status.Processing)

    await mint(AWC_ADDRESS, user.address)

    setStatus(Status.Received)

    window.location.href = 'https://line.me/R/oaMessage/%40642anxic'
  }, [setStatus, user])

  return (
    <div className="m-2 flex justify-center items-center">
      <div className="mt-8">
        <RegisterWallet>
          <div className="text-base space-y-3">
            <div>awaCoin配布</div>
            <div>100 awaCoin を受け取ることができます。</div>

            <div className="fixed bottom-10 z-999 left-0 w-full p-2">
              <PrimaryButton
                disabled={status !== Status.NotReceived}
                onClick={onReceive}
              >
                {status === Status.Received
                  ? '受け取り完了'
                  : status === Status.Processing
                  ? '...'
                  : '受け取る'}
              </PrimaryButton>
            </div>
          </div>
        </RegisterWallet>
      </div>
    </div>
  )
}

export default DemoView
