import { readContract } from 'viem/actions'
import { Address } from 'viem'
import { ethClient } from '../../utils/client'
import { CHAIN_ID } from '../../constants'
import { ENTRY_POINT_ADDRESS_MAP } from '@exzero/js-sdk'

export const NonceManagerAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      {
        internalType: 'uint192',
        name: 'key',
        type: 'uint192'
      }
    ],
    name: 'getNonce',
    outputs: [
      {
        internalType: 'uint256',
        name: 'nonce',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
] as const

export async function getERC4337Nonce(address: Address, key: bigint) {
  const nonce = await readContract(ethClient, {
    // TODO: entry point
    address: ENTRY_POINT_ADDRESS_MAP[CHAIN_ID],
    abi: NonceManagerAbi,
    functionName: 'getNonce',
    args: [address, key]
  })

  return nonce
}
