import React from 'react'
import { Address } from 'viem'
import { useBalance } from '../exzero/react/useBalance'
import { LoadingIndicatorDark } from './LoadingIndicator'

export const CoinBalance = ({
  erc20Address,
  unit
}: {
  erc20Address: Address
  unit: string
}) => {
  const balance = useBalance(erc20Address)

  return (
    <div className="mt-8 w-48 p-4 shadow-lg rounded-xl border-primary border-[1px]">
      <div>
        <div className="text-base text-zinc-700 flex justify-center">
          コイン保有数
        </div>
        {balance.isSuccess ? (
          <div className="text-xl font-bold text-zinc-900 flex justify-center">
            {Number(balance.data)} {unit}
          </div>
        ) : (
          <LoadingIndicatorDark />
        )}
      </div>
    </div>
  )
}
