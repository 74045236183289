import { Address, erc20Abi } from 'viem'
import { readContract } from 'viem/actions'
import { useQuery } from '@tanstack/react-query'
import { ethClient } from '../../utils/client'
import { PERMIT2_ADDRESS } from '../../constants'
import useExZero from './use-exzero'

export function useBalance(token: Address) {
  const { user } = useExZero()

  const query = useQuery({
    queryKey: ['balance', token, user?.address],
    queryFn: async () => {
      if (!user) {
        throw new Error('Failed to get user')
      }

      return await readContract(ethClient, {
        address: token,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [user.address]
      })
    },
    enabled: !!user,
    refetchInterval: 10000
  })

  return query
}

export function useAllowance(token: Address) {
  const { user } = useExZero()

  const query = useQuery({
    queryKey: ['allowance', token, user?.address],
    queryFn: async () => {
      if (!user) {
        throw new Error('Failed to get user')
      }

      return await readContract(ethClient, {
        address: token,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [user.address, PERMIT2_ADDRESS]
      })
    },
    enabled: !!user
  })

  return query
}
