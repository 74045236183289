import React, { useCallback, useEffect, useState } from 'react'
import { PrimaryButton } from '../components/Button'
import { Hex, zeroHash } from 'viem'
import { CHAIN_ID, PERMIT2_ADDRESS } from '../constants'
import { LoadingIndicatorDark } from '../components/LoadingIndicator'
import useExZero from '../exzero/react/use-exzero'
import image1 from '../assets/image1.png'
import image2 from '../assets/image2.webp'
import image3 from '../assets/image3.webp'
import image4 from '../assets/image4.webp'
import image5 from '../assets/image5.webp'
import image6 from '../assets/image6.webp'
import { TransferWithSecretRequest } from '@exzero/js-sdk'
import Linkify from 'linkify-react'
import { Link } from 'wouter'
import { RequestStatus, useRequestStatus } from '../hooks/useRequest'
import { QRModal } from '../components/QRModal'
import { Header } from '../components/Header'

function getImageSrc(imageType: string) {
  switch (imageType) {
    case '1':
      return image1
    case '2':
      return image2
    case '3':
      return image3
    case '4':
      return image4
    case '5':
      return image5
    case '6':
      return image6
    default:
      return null
  }
}

const GiftImage = ({ imageType }: { imageType: string }) => {
  const src = getImageSrc(imageType)

  if (src) {
    return <img className="w-80 h-80 rounded" src={src} alt="gift image" />
  } else {
    return <></>
  }
}

const TransferPendingView = () => {
  const params = new URLSearchParams(window.location.search)
  const [request, setRequest] = useState<TransferWithSecretRequest | null>(null)
  const [message, setMessage] = useState('')
  const [isQROpen, setIsQROpen] = React.useState(false)

  const [imageType, setImageType] = useState('no_image')
  const [amount, setAmount] = React.useState<bigint | undefined>(undefined)
  const [isNotFound, setIsNotFound] = React.useState(false)

  const requestStatus = useRequestStatus(
    CHAIN_ID,
    request ? request.getRequestId() : zeroHash
  )

  const { error, loadNicknames, getLinkTransfer } = useExZero()

  const id = params.get('id')
  const secret = params.get('s')
  const recipientLink =
    !!id && !!secret
      ? `${
          location.origin
        }/receive?openExternalBrowser=1&id=${encodeURIComponent(
          id
        )}&s=${encodeURIComponent(secret)}`
      : ''

  useEffect(() => {
    if (id) {
      getLinkTransfer(id).then(message => {
        if (message === null) {
          setIsNotFound(true)
          return
        }
        setMessage(message.message)
        setImageType(message.imageType)

        const request = TransferWithSecretRequest.parse(
          message.request as Hex,
          CHAIN_ID,
          PERMIT2_ADDRESS
        )

        setRequest(request)

        setAmount(request.params.amount)

        loadNicknames([request.params.sender])
      })
    }
  }, [id, getLinkTransfer, setRequest, setAmount, loadNicknames])

  /*
  const onReceive = useCallback(async () => {
    if (!secret) {
      throw new Error('Invalid secret')
    }

    if (linkTransfer === null) {
      throw new Error('Link transfer is null')
    }

    setStatus(Status.Processing)

    if (await receiveLinkTransfer(linkTransfer, secret as Hex)) {
      setStatus(Status.Received)

      window.location.href = '/'
    } else {
      setStatus(Status.NotReceived)
    }
  }, [setStatus, secret, linkTransfer, receiveLinkTransfer])
  */

  const onSendByShare = useCallback(async () => {
    const textMessage = `${recipientLink}`

    try {
      await navigator.share({
        text: textMessage
      })
    } catch (e) {
      console.error(e)
    }
  }, [recipientLink])

  if (recipientLink === '' || isNotFound) {
    return (
      <div className="m-2 flex justify-center items-center">
        <div className="mt-8">
          <div className="text-base space-y-3">
            <div>このリンクは見つかりません</div>
            <div>
              <Link href="/" className="text-blue-600 underline">
                ホームに戻る。
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (request === null || requestStatus.data === undefined) {
    return <LoadingIndicatorDark />
  }

  if (requestStatus.data !== RequestStatus.Pending) {
    return (
      <div className="m-2 flex justify-center items-center">
        <div className="mt-8">
          <div className="text-base space-y-3">
            <div>受け取り済みです</div>
            <PrimaryButton
              onClick={() => {
                window.close()
              }}
            >
              閉じる
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Header title="リンク送付中" returnPath="/history" />
      <div className="m-2 p-2 flex justify-center items-center">
        <div className="mt-8">
          <div className="text-base space-y-3">
            {amount ? (
              <div>{Number(amount)} awaCoinを送付しています</div>
            ) : (
              <div>読み込み中です</div>
            )}

            <div className="flex justify-center">
              <div className="mt-8 ">
                <div className="text-sm text-gray">メッセージ</div>
                <div className="text-base text-black">
                  <Linkify
                    as="p"
                    options={{
                      className: 'text-blue-500 underline'
                    }}
                  >
                    {message}
                  </Linkify>
                </div>
              </div>
            </div>

            <div className="pb-24 flex justify-start items-center">
              <GiftImage imageType={imageType} />
            </div>

            <div className="pb-24 flex justify-start items-center">
              {recipientLink.length > 0 ? (
                <QRModal
                  isOpen={isQROpen}
                  onRequestClose={() => {
                    setIsQROpen(false)
                  }}
                  url={recipientLink}
                />
              ) : null}
            </div>

            <div className="fixed bottom-10 z-999 left-0 w-full p-2">
              <div className="text-xs text-red-700">
                {error ? 'サーバの調子が悪いようです' : null}
              </div>

              <div className="flex justify-between space-x-1">
                <PrimaryButton
                  onClick={() => {
                    setIsQROpen(true)
                  }}
                >
                  QRを表示する
                </PrimaryButton>
                <PrimaryButton onClick={onSendByShare}>
                  他のツール
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransferPendingView
