import React, { useCallback, useState } from 'react'
import { PrimaryButton, SubButton } from '../components/Button'
import { useAllowance, useBalance } from '../exzero/react/useBalance'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { CoinBalance } from '../components/CoinBalance'
import useExZero from '../exzero/react/use-exzero'
import { AWC_ADDRESS } from '../constants'
import { Header } from '../components/Header'
import { QRModal } from '../components/QRModal'

function getNumber(text: string) {
  const num = parseInt(text)

  if (!Number.isNaN(num)) {
    return num
  } else {
    return null
  }
}

const PaymentView = () => {
  const [amountText, setAmount] = React.useState('0')
  const amount = getNumber(amountText)

  const [isQROpen, setIsQROpen] = React.useState(false)

  const balance = useBalance(AWC_ADDRESS)
  const allowance = useAllowance(AWC_ADDRESS)

  const [isCreatingMessageLoading, setIsCreatingMessageLoading] =
    useState(false)

  const [recipientLink, setRecipientLink] = useState('')
  const { error, approve, transferByLink } = useExZero()

  const onTransfer = useCallback(async () => {
    setIsCreatingMessageLoading(true)

    if (!allowance.isSuccess) {
      throw new Error('Failed to generate allowance')
    }

    if (amount === null || amount === 0) {
      throw new Error('amount must not be null')
    }

    if (allowance.data < 1000n) {
      try {
        await approve(AWC_ADDRESS)
      } catch (e) {
        console.error(e)
        setIsCreatingMessageLoading(false)
      }
    }

    const expiration = Math.floor(new Date('2024-08-31').getTime() / 1000)

    const transferResponse = await transferByLink(
      AWC_ADDRESS,
      BigInt(amount),
      {
        message: '',
        imageType: 'no_image'
      },
      expiration
    )

    if (transferResponse) {
      const { id, secret } = transferResponse
      const _recipientLink = `${
        location.origin
      }/receive?openExternalBrowser=1&id=${encodeURIComponent(
        id
      )}&s=${encodeURIComponent(secret)}`

      console.log(_recipientLink)

      setRecipientLink(_recipientLink)

      setIsCreatingMessageLoading(false)
    } else {
      setIsCreatingMessageLoading(false)
    }
  }, [
    amount,
    allowance.isSuccess,
    allowance.data,
    approve,
    setRecipientLink,
    transferByLink
  ])

  const onSendByShare = useCallback(async () => {
    const textMessage = `${recipientLink}`

    try {
      await navigator.share({
        text: textMessage
      })
    } catch (e) {
      console.error(e)
    }
  }, [recipientLink])

  if (!balance.isSuccess) {
    return (
      <div className="m-2 flex justify-center items-center">
        <div className="mt-8">
          <LoadingIndicator />
        </div>
      </div>
    )
  }

  return (
    <div>
      <Header title="支払い" />
      <div className="p-4 flex justify-center items-center">
        <div className="text-base space-y-3">
          <div className="flex justify-center">
            <CoinBalance erc20Address={AWC_ADDRESS} unit={'awaCoin'} />
          </div>

          <div className="mt-4 pb-32 space-y-4">
            <div className="space-y-2">
              <div className="text-zinc-700">送る枚数を決めてください。</div>
              <div className="flex justify-between items-center space-x-2 text-sm">
                <div className="basis-1/4">
                  <SubButton
                    onClick={() => {
                      setAmount('1')
                    }}
                  >
                    1
                  </SubButton>
                </div>
                <div className="basis-1/4">
                  <SubButton
                    onClick={() => {
                      setAmount('2')
                    }}
                  >
                    2
                  </SubButton>
                </div>
                <div className="basis-2/4">
                  <div className="p-2 shadow text-base text-zinc-950 flex justify-center items-center w-full bg-transparent h-10 rounded-lg border border-zinc-950/10">
                    <div className="w-full h-full">
                      <input
                        className="w-full h-full bg-transparent text-right pr-3"
                        type="number"
                        value={amountText}
                        onChange={e => setAmount(e.target.value)}
                      />
                    </div>

                    <span className="text-black">awaCoin</span>
                  </div>
                </div>
              </div>
            </div>

            {recipientLink.length > 0 ? (
              <QRModal
                isOpen={isQROpen}
                onRequestClose={() => {
                  setIsQROpen(false)
                }}
                url={recipientLink}
              />
            ) : null}
          </div>

          <div className="fixed bottom-10 z-999 left-0 w-full p-4 space-y-4">
            <div className="text-xs text-red-700">
              {error ? 'サーバの調子が悪いようです' : null}
              {amount === null ? '数量が不正です' : null}
              {amount !== null && amount > Number(balance.data)
                ? '残高が足りません'
                : null}
            </div>

            {recipientLink.length > 0 ? (
              <div className="flex justify-between space-x-1">
                <PrimaryButton
                  onClick={() => {
                    setIsQROpen(true)
                  }}
                >
                  QRを表示する
                </PrimaryButton>
                <PrimaryButton onClick={onSendByShare}>
                  他のツール
                </PrimaryButton>
              </div>
            ) : (
              <PrimaryButton
                disabled={
                  amount === null ||
                  amount === 0 ||
                  amount > Number(balance.data)
                }
                onClick={onTransfer}
              >
                {isCreatingMessageLoading ? (
                  <LoadingIndicator />
                ) : (
                  'メッセージを作る'
                )}
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentView
