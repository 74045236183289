import { useQuery } from '@tanstack/react-query'
import {
  Address,
  PublicClient,
  concat,
  hexToBigInt,
  isAddressEqual,
  pad,
  toHex,
  zeroAddress
} from 'viem'
import { Permit2ABI } from '../abis/Permit2'
import { PERMIT2_ADDRESS } from '../constants'
import { ethClient } from '../utils/client'
import { readContract } from 'viem/actions'

function getNow() {
  return Math.floor(new Date().getTime() / 1000)
}

export function usePermit2Nonce(
  chainId: number,
  account: Address | null,
  spender: Address
) {
  return useQuery({
    queryKey: ['nonceBitmap', chainId, account, spender],
    queryFn: async () => {
      if (!account) {
        throw new Error('Failed to generate key')
      }

      return getPermit2Nonce(account, spender)
    },
    enabled: !!account
  })
}

export function useIsValidNonce(
  chainId: number,
  account: Address,
  nonce: bigint
) {
  return useQuery({
    queryKey: ['nonceBitmap', chainId, account, nonce.toString()],
    queryFn: async () => {
      const { wordPos, bitPos } = bitmapPositions(nonce)

      const bitmap = await readContract(ethClient, {
        address: PERMIT2_ADDRESS,
        abi: Permit2ABI,
        functionName: 'nonceBitmap',
        args: [account, BigInt(wordPos)]
      })

      const bit = 1n << bitPos

      const flipped = bitmap ^ bit

      return (flipped & bit) === bit
    },
    enabled: ethClient !== undefined && !isAddressEqual(account, zeroAddress)
  })
}

export async function getPermit2Nonce(account: Address, spender: Address) {
  const now = getNow()

  // wordPos is 248 bits, so the size is 31 bytes
  const currentWordPos = hexToBigInt(
    pad(concat([pad(spender, { size: 20 }), toHex(now, { size: 4 })]), {
      size: 31,
      dir: 'right'
    })
  )

  const { word, bitmap } = await getNextOpenWord(
    ethClient,
    PERMIT2_ADDRESS,
    account,
    currentWordPos
  )

  return buildNonce(word, getFirstUnsetBit(bitmap))
}

async function getNextOpenWord(
  client: PublicClient,
  address: Address,
  account: Address,
  initialWord: bigint
) {
  let currentWord = initialWord
  let bitmap = 0n

  do {
    bitmap = await readContract(client, {
      address,
      abi: Permit2ABI,
      functionName: 'nonceBitmap',
      args: [account, BigInt(currentWord)]
    })

    currentWord = currentWord + 1n
  } while (bitmap === 2n ** 256n)

  return {
    word: currentWord - 1n,
    bitmap: bitmap
  }
}

function getFirstUnsetBit(bitmap: bigint): number {
  // Optimization if switch to library w/ bitwise operators:
  // return ~bitmap + (bitmap + 1)
  // instead we have to do a loop

  for (let i = 0; i < 256; i++) {
    if ((bitmap / 2n ** BigInt(i)) % 2n === 0n) {
      return i
    }
  }
  return -1
}

function buildNonce(word: bigint, bitPos: number): bigint {
  // word << 8
  const shiftedWord = word * 256n
  return shiftedWord + BigInt(bitPos)
}

function bitmapPositions(nonce: bigint) {
  const wordPos = nonce >> 8n
  const bitPos = nonce & 0xffn

  return {
    wordPos,
    bitPos
  }
}
