import { Address, Hex, getAddress } from 'viem'
import { ExZeroUser } from '../client/types'
import { WalletState } from './wallet-state'
import { CoinMovingHistoryEntity } from '../client/query/coin-moving'
import { splitAddress } from './utils'
import { OnetimeLockEntity } from '../client/query/onetime-lock'

type Action =
  | {
      type: 'CHECK_PASSKEY_AVAILABLE'
      isPasskeyAvailable: boolean
    }
  | { type: 'REGISTER' }
  | {
      type: 'INITIALIZED' | 'SIGN_MESSAGE'
      user?: ExZeroUser
    }
  | { type: 'UPDATE_HISTORY'; history: CoinMovingHistoryEntity[] }
  | { type: 'FETCH_ALL_HISTORY'; allHistory: CoinMovingHistoryEntity[] }
  | { type: 'UPDATE_ONETIME_LOCK_HISTORY'; history: OnetimeLockEntity[] }
  | { type: 'UPDATE_USER'; user: ExZeroUser }
  | {
      type: 'FETCH_NICKNAME'
      nicknames: { ethAddress: Address; nickname: string }[]
    }
  | { type: 'ERROR'; error: Error }

/**
 * Handles how that state changes in the `useExZero` hook.
 */
export const reducer = (state: WalletState, action: Action): WalletState => {
  switch (action.type) {
    case 'CHECK_PASSKEY_AVAILABLE':
      return {
        ...state,
        isPasskeyAvailable: action.isPasskeyAvailable
      }
    case 'REGISTER':
      return {
        ...state,
        isLoading: true
      }
    case 'INITIALIZED':
    case 'SIGN_MESSAGE':
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        isLoading: false,
        error: undefined
      }
    case 'UPDATE_HISTORY':
      return {
        ...state,
        history: updateHistory(action.history, state.nicknames)
      }
    case 'FETCH_ALL_HISTORY':
      return {
        ...state,
        allHistory: updateHistory(action.allHistory, state.nicknames)
      }
    case 'UPDATE_ONETIME_LOCK_HISTORY':
      return {
        ...state,
        onetimeLockHistory: updateOnetimeLockHistory(
          action.history,
          state.nicknames
        )
      }
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.user
      }
    case 'FETCH_NICKNAME': {
      const newNicknames = action.nicknames.reduce(
        (acc, { ethAddress, nickname }) => ({
          ...acc,
          [ethAddress]: nickname
        }),
        state.nicknames
      )

      return {
        ...state,
        history: updateHistory(state.history || [], newNicknames),
        allHistory: updateHistory(state.allHistory || [], newNicknames),
        nicknames: newNicknames
      }
    }
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
  }
}

function updateHistory(
  history: CoinMovingHistoryEntity[],
  nicknames: Record<string, string>
) {
  return history.map(item => {
    const sender = getAddress(item.sender)
    const recipient = getAddress(item.recipient)

    return {
      ...item,
      txHash: item.txHash as Hex,
      sender,
      recipient,
      senderDisplayName: nicknames[sender]
        ? nicknames[sender]
        : splitAddress(sender),
      recipientDisplayName: nicknames[recipient]
        ? nicknames[recipient]
        : splitAddress(recipient)
    }
  })
}

function updateOnetimeLockHistory(
  history: OnetimeLockEntity[],
  nicknames: Record<string, string>
) {
  return history.map(item => {
    const sender = getAddress(item.sender)
    const recipient = item.recipient ? getAddress(item.recipient) : undefined

    return {
      ...item,
      txHash: item.txHash as Hex,
      sender,
      recipient,
      senderDisplayName: nicknames[sender]
        ? nicknames[sender]
        : splitAddress(sender),
      recipientDisplayName: recipient
        ? nicknames[recipient]
          ? nicknames[recipient]
          : splitAddress(recipient)
        : undefined
    }
  })
}
