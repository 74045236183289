import {
  startAuthentication,
  startRegistration,
  base64URLStringToBuffer,
  bufferToBase64URLString
} from '@simplewebauthn/browser'
import { Hex } from 'viem'
import { getMessageSignature, getPublicKeyFromBytes } from './authnhelpers'
import base64url from 'base64url'
import { arrayify, hexlify } from 'ethers/lib/utils'
import dayjs from 'dayjs'
import { WebAuthnAuth } from '@exzero/js-sdk/dist/datatypes/WebAuthnAuth'

function createDisplayKeyName(projectName: string) {
  const date = dayjs()
  const y = date.year()
  const m = date.month() + 1
  const d = date.date()
  return `${projectName} ${y}/${m}/${d}`
}

function getChallenge() {
  const array = new Uint8Array(32)

  crypto.getRandomValues(array)

  return bufferToBase64URLString(array)
}

export async function registerByWebAuthn(
  projectName: string,
  userHandle?: string
) {
  const id = userHandle || crypto.randomUUID()

  const keyName = createDisplayKeyName(projectName)

  const excludedCredentials: { id: string; type: 'public-key' }[] = userHandle
    ? [
        {
          id: userHandle,
          type: 'public-key'
        }
      ]
    : []

  const result = await startRegistration({
    rp: {
      id: location.hostname,
      name: projectName
    },
    user: {
      id: id,
      name: keyName,
      displayName: keyName
    },
    attestation: 'none',
    authenticatorSelection: {
      authenticatorAttachment: 'platform',
      userVerification: 'required',
      residentKey: 'required',
      requireResidentKey: true
    },
    excludeCredentials: excludedCredentials,
    challenge: getChallenge(),
    // The Smart Wallet supports only ES256.
    pubKeyCredParams: [
      {
        type: 'public-key',
        alg: -7 // ES256
      }
    ],
    extensions: { credProps: true }
  })

  const publicKey = await getPublicKeyFromBytes(result.response.publicKey!)

  return {
    publicKey,
    userHandler: id,
    name: keyName
  }
}

export async function getUserHandle() {
  const credential = await startAuthentication({
    challenge: getChallenge(),
    userVerification: 'required'
  })

  return credential.response.userHandle
}

export async function signByWebAuthn(message: string) {
  /*
  const decodedId = base64url.decode(encodedId);
  const credential = await navigator.credentials.get({
    publicKey: {
      allowCredentials: [
        {
          id: decodedId,
          type: "public-key",
        },
      ],
      challenge: Uint8Array.from(message, (c) => c.charCodeAt(0)).buffer,
      // Set the required authentication factors
      userVerification: "required",
    },
  });
  */

  // const challenge = base64url.encode(message.slice(2), 'hex').replace(/=/g, '')
  const challenge = bufferToBase64URLString(arrayify(message))
  // base64url.encode(message.slice(2), 'hex').replace(/=/g, '')

  const credential = await startAuthentication({
    challenge: challenge,
    userVerification: 'required'
  })

  const response = credential.response
  // const clientDataJSON = Buffer.from(response.clientDataJSON);

  const rs = getMessageSignature(response.signature)

  const clientDataJSON = base64url.decode(response.clientDataJSON, 'utf8')

  /// This is related to the guard to prevent signature malleability issue
  if (
    rs[1] >
    57896044605178124381348723474703786764998477612067880171211129530534256022184n
  ) {
    rs[1] =
      0xffffffff00000000ffffffffffffffffbce6faada7179e84f3b9cac2fc632551n -
      rs[1]
  }

  const webAuthnAuth = new WebAuthnAuth({
    authenticatorData: hexlify(
      new Uint8Array(base64URLStringToBuffer(response.authenticatorData))
    ) as Hex,
    clientDataJSON: clientDataJSON,
    challengeIndex: BigInt(23),
    typeIndex: BigInt(1),
    r: rs[0],
    s: rs[1]
  })

  const signature = webAuthnAuth.serialize()

  return {
    userHandle: response.userHandle,
    name: '',
    signature
  }
}
