import { Hex } from 'viem'

export const ONETIME_LOCK_QUERY = `query onetimeLocks($address: Bytes!, $token: Bytes!) {
    onetimeLocks(
      first: 100
      orderBy: createdAt
      orderDirection: desc
      where: {sender: $address, token: $token}
    ) {
      id
      amount
      expiry
      createdAt
      updatedAt
      metadata
      status
      recipient {
        id
      }
      sender {
        id
      }
      token {
        id
      }
      txHash
    }
  }`

export interface OnetimeLocksEntity {
  onetimeLocks: {
    id: string
    token: {
      id: string
    }
    sender: {
      id: string
    }
    recipient?: {
      id: string
    }
    amount: string
    metadata: string
    txHash: string
    createdAt: string
    updatedAt: string
    status: 'LIVE' | 'COMPLETED' | 'CANCELLED'
  }[]
}

export interface OnetimeLockEntity {
  id: string
  token: string
  sender: string
  recipient?: string
  amount: string
  metadata: string
  txHash: string
  status: 'LIVE' | 'COMPLETED' | 'CANCELLED'
  createdAt: number
  updatedAt: number
  secret?: Hex
  messageId?: string
}
