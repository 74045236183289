import React from 'react'
import { Switch, Router, Route } from 'wouter'
import Modal from 'react-modal'
import DemoView from './pages/DemoView'
import TransferView from './pages/TransferView'
import TransferPendingView from './pages/TransferPendingView'
import PaymentView from './pages/PaymentView'
import ReceiveView from './pages/ReceiveView'
import DirectReceiveView from './pages/DirectReceiveView'
import DirectTransferView from './pages/DirectTransferView'
import BalanceView from './pages/BalanceView'
import ScanView from './pages/ScanView'
import ProfileView from './pages/ProfileView'
import HistoryView from './pages/HistoryView'
import SettingsView from './pages/SettingsView'
import StoreReceiveView from './pages/StoreReceiveView'
import StoreTransferView from './pages/StoreTransferView'
import NotFound from './components/NotFound'
import GraphView from './pages/GraphView'

Modal.setAppElement('#root')

const App = () => {
  return (
    <div className="h-full text-black">
      <Router>
        <Switch>
          <Route path="/demo" component={DemoView} />
          <Route path="/profile" component={ProfileView} />
          <Route path="/history" component={HistoryView} />
          <Route path="/receive" component={ReceiveView} />
          <Route path="/transfer" component={TransferView} />
          <Route path="/pending" component={TransferPendingView} />
          <Route path="/payment" component={PaymentView} />
          <Route path="/dreceive" component={DirectReceiveView} />
          <Route path="/dtransfer" component={DirectTransferView} />
          <Route path="/scan" component={ScanView} />
          <Route path="/" component={BalanceView} />
          <Route path="/settings" component={SettingsView} />
          <Route path="/store-receive" component={StoreReceiveView} />
          <Route path="/store-transfer" component={StoreTransferView} />
          <Route path="/graph" component={GraphView} />
          <Route path="/:rest*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
