import React, { useCallback } from 'react'
import useExZero from '../exzero/react/use-exzero'
import { TextInput } from '../components/common/TextInput'
import { PrimaryButton } from '../components/Button'
import { LoadingIndicatorDark } from '../components/LoadingIndicator'
import { Header } from '../components/Header'

const MAX_NICKNAME_LENGTH = 32

const ProfileView = () => {
  const { user } = useExZero()

  console.log(user)

  return (
    <div>
      <Header title="プロフィール" />
      <div className="m-2 flex justify-center items-center">
        <div className="text-base space-y-3">
          <div className="fixed bottom-8 z-999 left-0 w-full p-2">
            {user ? (
              <NickNameForm nickname={user.nickname} />
            ) : (
              <LoadingIndicatorDark />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const NickNameForm = ({ nickname }: { nickname: string }) => {
  const { updateNickName } = useExZero()

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isSaved, setIsSaved] = React.useState<boolean>(false)

  const [nickName, setNickName] = React.useState<string>(nickname)

  const onUpdate = useCallback(async () => {
    setIsLoading(true)
    await updateNickName(nickName)
    setIsLoading(false)
    setIsSaved(true)
  }, [updateNickName, nickName])

  return (
    <div className="px-3 py-2 space-y-3">
      <div className="text-sm">ニックネーム</div>
      <div className="text-xs">
        ニックネームは、他の人も見ることができます。
      </div>
      <TextInput
        placeholder="ニックネーム"
        value={nickName}
        onChange={setNickName}
      />
      <PrimaryButton
        onClick={onUpdate}
        disabled={
          nickName.length === 0 ||
          nickName.length > MAX_NICKNAME_LENGTH ||
          isLoading
        }
      >
        {isLoading ? '保存中...' : '保存'}
      </PrimaryButton>
      <div className="text-xs text-green-600 h-6">
        {isSaved ? '保存に成功しました' : ' '}
      </div>
    </div>
  )
}

export default ProfileView
