export const OnetimeLockRequestDispatcherAbi = [
  {
    type: 'constructor',
    inputs: [
      {
        name: '_permit2',
        type: 'address',
        internalType: 'address'
      },
      {
        name: '_facilitator',
        type: 'address',
        internalType: 'address'
      }
    ],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'cancelRequest',
    inputs: [
      {
        name: 'id',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'completeRequest',
    inputs: [
      {
        name: 'id',
        type: 'bytes32',
        internalType: 'bytes32'
      },
      {
        name: 'recipientData',
        type: 'tuple',
        internalType: 'struct OnetimeLockRequestDispatcher.RecipientData',
        components: [
          {
            name: 'recipient',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'sig',
            type: 'bytes',
            internalType: 'bytes'
          },
          {
            name: 'metadata',
            type: 'bytes',
            internalType: 'bytes'
          }
        ]
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'facilitator',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'getRequestId',
    inputs: [
      {
        name: 'request',
        type: 'tuple',
        internalType: 'struct TransferWithSecretRequest',
        components: [
          {
            name: 'dispatcher',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'sender',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'deadline',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'nonce',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'amount',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'token',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'publicKey',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'metadata',
            type: 'bytes',
            internalType: 'bytes'
          }
        ]
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'pendingRequests',
    inputs: [
      {
        name: '',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    outputs: [
      {
        name: 'amount',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'token',
        type: 'address',
        internalType: 'address'
      },
      {
        name: 'publicKey',
        type: 'address',
        internalType: 'address'
      },
      {
        name: 'sender',
        type: 'address',
        internalType: 'address'
      },
      {
        name: 'nonce',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'expiry',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'status',
        type: 'uint8',
        internalType: 'enum OnetimeLockRequestDispatcher.RequestStatus'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'submitRequest',
    inputs: [
      {
        name: 'request',
        type: 'tuple',
        internalType: 'struct TransferWithSecretRequest',
        components: [
          {
            name: 'dispatcher',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'sender',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'deadline',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'nonce',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'amount',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'token',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'publicKey',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'metadata',
            type: 'bytes',
            internalType: 'bytes'
          }
        ]
      },
      {
        name: 'sig',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'event',
    name: 'RequestCancelled',
    inputs: [
      {
        name: 'id',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32'
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'RequestCompleted',
    inputs: [
      {
        name: 'id',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32'
      },
      {
        name: 'recipient',
        type: 'address',
        indexed: false,
        internalType: 'address'
      },
      {
        name: 'metadata',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes'
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'RequestSubmitted',
    inputs: [
      {
        name: 'id',
        type: 'bytes32',
        indexed: false,
        internalType: 'bytes32'
      },
      {
        name: 'token',
        type: 'address',
        indexed: false,
        internalType: 'address'
      },
      {
        name: 'sender',
        type: 'address',
        indexed: false,
        internalType: 'address'
      },
      {
        name: 'amount',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256'
      },
      {
        name: 'expiry',
        type: 'uint256',
        indexed: false,
        internalType: 'uint256'
      },
      {
        name: 'metadata',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes'
      }
    ],
    anonymous: false
  },
  {
    type: 'error',
    name: 'CallerIsNotSenderOrFacilitator',
    inputs: []
  },
  {
    type: 'error',
    name: 'DeadlinePassed',
    inputs: []
  },
  {
    type: 'error',
    name: 'InvalidDispatcher',
    inputs: []
  },
  {
    type: 'error',
    name: 'InvalidSecret',
    inputs: []
  },
  {
    type: 'error',
    name: 'RecipientNotSet',
    inputs: []
  },
  {
    type: 'error',
    name: 'RequestAlreadyExists',
    inputs: []
  },
  {
    type: 'error',
    name: 'RequestExpired',
    inputs: []
  },
  {
    type: 'error',
    name: 'RequestIsNotPending',
    inputs: []
  }
] as const
