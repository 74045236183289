import { Address, Hex } from 'viem'
import { UserOperation } from '@exzero/js-sdk'

const FILLER_API_ENDPOINT2 =
  'https://0omsrjfp2l.execute-api.ap-northeast-1.amazonaws.com/dev/demo/wallet'

export class ExZeroApiService {
  constructor(
    public chainId: number,
    public apiKey: string,
    public endpoint: string
  ) {}

  async register(owner: Hex, userHandle: string, name: string) {
    return await this._post(
      `wallet/register`,
      {
        chainId: this.chainId,
        owner,
        userHandle,
        name
      },
      true
    )
  }

  async updateNickName(
    ethAddress: Address,
    nickname: string,
    signature: Hex,
    timestamp: number
  ) {
    return await this._put(`wallet/nickname`, {
      chainId: this.chainId,
      ethAddress,
      nickname,
      signature,
      timestamp: timestamp.toString()
    })
  }

  async getNickNames(ethAddressList: Address[]) {
    return (await this._get(
      `wallet/nicknames?ethAddressList=${encodeURIComponent(
        JSON.stringify(ethAddressList)
      )}`
    )) as {
      ethAddress: Address
      nickname: string
    }[]
  }

  async signPaymasterAndData(userOperation: UserOperation) {
    const result = await this._post(`transaction/sign-pad`, {
      chainId: this.chainId,
      encodedUserOp: userOperation.serialize()
    })

    return result.paymasterAndData as Hex
  }

  async execute(userOperation: UserOperation, signature: Hex) {
    return await this._post(`transaction/execute`, {
      chainId: this.chainId,
      encodedUserOp: userOperation.serialize(),
      signature
    })
  }

  async submit(type: string, data: any) {
    return await this._post(
      `transaction/submit`,
      {
        chainId: this.chainId,
        type,
        data: {
          ...data,
          type
        }
      },
      true
    )
  }

  async getEthAddressByUserHandle(userHandle: string) {
    const result = await this._get(`wallet/wallets/${userHandle}`)

    return result as {
      id: string
      name: string
      ethAddress: string
    }
  }

  async getAddress(owner: Hex) {
    return this._get(`wallet/address?owner=${owner}&chainId=${this.chainId}`)
  }

  async createMessage(
    messageId: string,
    request: Hex,
    signature: Hex,
    message: string,
    imageType: string
  ) {
    return await this._post(`transaction/messages`, {
      messageId,
      request,
      signature,
      message,
      imageType
    })
  }

  async getMessage(messageId: string) {
    const data = await this._get(`transaction/messages?messageId=${messageId}`)

    return data as {
      messageId: string
      messageFormat: string
      messageBody: string
    }
  }

  async getHistory(operationName: string, query: string, variables: any) {
    return await this._post(`transaction/history`, {
      chainId: this.chainId,
      operationName,
      query,
      variables
    })
  }

  async _post(path: string, data: any, retry = false) {
    const execute = async () => {
      return await fetch(`${this.endpoint}/${path}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.apiKey
        },
        body: JSON.stringify(data)
      })
    }

    const res = await execute()

    if (res.status >= 500 && res.status < 600) {
      if (!retry) {
        throw new Error('Server Error')
      }

      const res2 = await execute()

      if (res2.status >= 500 && res2.status < 600) {
        throw new Error('Server Error')
      } else {
        return await res2.json()
      }
    } else {
      return await res.json()
    }
  }

  async _put(path: string, data: any) {
    const res = await fetch(`${this.endpoint}/${path}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      },
      body: JSON.stringify(data)
    })

    return await res.json()
  }

  async _get(url: string) {
    const res = await fetch(`${this.endpoint}/${url}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      }
    })

    return await res.json()
  }
}

export async function mint(token: Address, recipient: Address) {
  return await _post(`${FILLER_API_ENDPOINT2}/sw/mint`, {
    token,
    amount: '1000',
    recipient
  })
}

async function _post(url: string, data: any) {
  const res = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

  if (res.status >= 500 && res.status < 600) {
    throw new Error('Server Error')
  }

  return await res.json()
}
