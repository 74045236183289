export const COIN_MOVING_HISTORY_QUERY = `query coinMovingHistories($address: Bytes!, $token: Bytes!) {
    coinMovingHistories(
      first: 100
      orderBy: createdAt
      orderDirection: desc
      where: {or: [
        {sender: $address, token: $token},
        {recipient: $address, token: $token}
      ]}
    ) {
      movingType
      amount
      createdAt
      id
      metadata
      recipient {
        id
      }
      sender {
        id
      }
      token {
        id
      }
      txHash
    }
  }`

export const COIN_MOVING_ALL_HISTORY_QUERY = `query coinMovingHistories($token: Bytes!) {
  coinMovingHistories(
    first: 200
    orderBy: createdAt
    orderDirection: desc
    where: {token: $token}
  ) {
    movingType
    amount
    createdAt
    id
    metadata
    recipient {
      id
    }
    sender {
      id
    }
    token {
      id
    }
    txHash
  }
}`

export interface CoinMovingHistoriesEntity {
  coinMovingHistories: {
    id: string
    movingType: string
    token: {
      id: string
    }
    sender: {
      id: string
    }
    recipient: {
      id: string
    }
    amount: string
    metadata: string
    txHash: string
    createdAt: string
  }[]
}

export interface CoinMovingHistoryEntity {
  id: string
  movingType: string
  token: string
  sender: string
  recipient: string
  amount: string
  metadata: string
  txHash: string
  createdAt: number
}
