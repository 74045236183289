import base64url from 'base64url'
import { ECDSASigValue } from '@peculiar/asn1-ecc'
import { AsnParser } from '@peculiar/asn1-schema'
import { BigNumber } from 'ethers'

function shouldRemoveLeadingZero(bytes: Uint8Array): boolean {
  return bytes[0] === 0x0 && (bytes[1] & (1 << 7)) !== 0
}

export async function getPublicKeyFromBytes(
  publicKeyBytes: string
): Promise<bigint[]> {
  const cap = {
    name: 'ECDSA',
    namedCurve: 'P-256',
    hash: 'SHA-256'
  }
  const pkeybytes = base64url.toBuffer(publicKeyBytes)
  const pkey = await crypto.subtle.importKey('spki', pkeybytes, cap, true, [
    'verify'
  ])
  const jwk = await crypto.subtle.exportKey('jwk', pkey)
  if (jwk.x && jwk.y)
    return [
      BigInt(BigNumber.from(base64url.toBuffer(jwk.x)).toString()),
      BigInt(BigNumber.from(base64url.toBuffer(jwk.y)).toString())
    ]
  else throw new Error('Invalid public key')
}

export function getMessageSignature(authResponseSignature: string): bigint[] {
  // See https://github.dev/MasterKale/SimpleWebAuthn/blob/master/packages/server/src/helpers/iso/isoCrypto/verifyEC2.ts
  // for extraction of the r and s bytes from the raw signature buffer
  const parsedSignature = AsnParser.parse(
    base64url.toBuffer(authResponseSignature),
    ECDSASigValue
  )

  let rBytes = new Uint8Array(parsedSignature.r)
  let sBytes = new Uint8Array(parsedSignature.s)

  if (shouldRemoveLeadingZero(rBytes)) {
    rBytes = rBytes.slice(1)
  }

  if (shouldRemoveLeadingZero(sBytes)) {
    sBytes = sBytes.slice(1)
  }

  // r and s values
  return [
    BigInt(BigNumber.from(rBytes).toString()),
    BigInt(BigNumber.from(sBytes).toString())
  ]
}

/*
export function getXYCoordinates(publicKeyBase64: string) {
  const publicKeyBuffer = Buffer.from(publicKeyBase64, 'base64');
  let b = Array.from(publicKeyBuffer);
  b = b.slice(-128)
  const x = b.slice(0, 32)
  const y = b.slice(-32)

  return concat(
    [
      '0x' + Buffer.from(x).toString('hex') as Hex,
      '0x' + Buffer.from(y).toString('hex') as Hex
    ]
  )
}

export function getRS(signatureBase64: string) {
  const sigBuf = Buffer.from(signatureBase64, 'base64');
  let b = Array.from(sigBuf);
  b = b.slice(-128)
  const x = b.slice(0, 32)
  const y = b.slice(-32)

  return concat(
    [
      '0x' + Buffer.from(x).toString('hex') as Hex,
      '0x' + Buffer.from(y).toString('hex') as Hex
    ]
  )
}
*/
