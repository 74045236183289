import {
  Address,
  Hex,
  encodeAbiParameters,
  hashTypedData,
  keccak256,
  toHex
} from 'viem'
import { PERMIT2_MAP, TransferWithSecretRequest } from '@exzero/js-sdk'
import { encodeSignatureBytes } from '@exzero/js-sdk/dist/datatypes/WebAuthnAuth'
import { CHAIN_ID, ONETIME_LOCK_DISPATCHER_ADDRESS } from '../../constants'
import { signByWebAuthn } from './WebAuthnAuth'
import { ethClient } from '../../utils/client'
import { SmartWalletAbi } from '../../abis/SmartWallet'
import { privateKeyToAddress, signMessage } from 'viem/accounts'

export async function signHash(hash: Hex) {
  const { signature } = await signByWebAuthn(hash)

  return encodeSignatureBytes(0n, signature)
}

export async function signLinkTransfer(
  chainId: number,
  token: Address,
  sender: Address,
  amount: bigint,
  nonce: bigint,
  expiration: number
) {
  const deadline = BigInt(expiration)

  const { secret, publicKey } = generateSecret()

  const request = new TransferWithSecretRequest(
    {
      dispatcher: ONETIME_LOCK_DISPATCHER_ADDRESS,
      sender: sender,
      deadline: deadline,
      nonce: nonce,
      amount: amount,
      token: token,
      publicKey: publicKey,
      metadata: '0x'
    },
    CHAIN_ID,
    PERMIT2_MAP[chainId]
  )

  const { domain, types, message } = request.permitData()

  console.log(message)

  const hash = hashTypedData({
    domain,
    types,
    message,
    primaryType: 'PermitWitnessTransferFrom'
  })

  const messageHash = createMessageHash(CHAIN_ID, hash, sender)

  const signature = await signHash(messageHash)

  const isValid = await ethClient.readContract({
    address: sender,
    abi: SmartWalletAbi,
    functionName: 'isValidSignature',
    args: [hash, signature]
  })

  if (isValid === '0xffffffff') {
    throw new Error('Invalid signature')
  }

  return {
    signature,
    secret,
    request: request.serialize()
  }
}

export function createMessageHash(
  chainId: number,
  hash: Hex,
  address: Address
) {
  return hashTypedData({
    domain: {
      name: 'ExZero Smart Wallet',
      version: '1',
      chainId: chainId,
      verifyingContract: address as Address
    },
    types: {
      ExZeroWalletMessage: [{ name: 'hash', type: 'bytes32' }]
    },
    message: {
      hash
    },
    primaryType: 'ExZeroWalletMessage'
  })
}

function generateKey() {
  const array = new Uint8Array(32)
  crypto.getRandomValues(array)
  return toHex(array)
}

function generateSecret() {
  const secret = generateKey()

  const publicKey = privateKeyToAddress(secret)

  return { secret, publicKey }
}

export function generateLinkTransferSignature(
  secret: Hex,
  dispatcher: Address,
  nonce: bigint,
  recipient: Address
) {
  return signMessage({
    privateKey: secret,
    message: {
      raw: keccak256(
        encodeAbiParameters(
          [
            { type: 'address', value: dispatcher },
            { type: 'uint256', value: nonce },
            { type: 'address', value: recipient }
          ],
          [dispatcher, nonce, recipient]
        )
      )
    }
  })
}
