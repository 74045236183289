export const SmartWalletAbi = [
  {
    type: 'constructor',
    inputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'fallback',
    stateMutability: 'payable'
  },
  {
    type: 'receive',
    stateMutability: 'payable'
  },
  {
    type: 'function',
    name: 'REPLAYABLE_NONCE_KEY',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'addOwnerAddress',
    inputs: [
      {
        name: 'owner',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'addOwnerPublicKey',
    inputs: [
      {
        name: 'x',
        type: 'bytes32',
        internalType: 'bytes32'
      },
      {
        name: 'y',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'canSkipChainIdValidation',
    inputs: [
      {
        name: 'functionSelector',
        type: 'bytes4',
        internalType: 'bytes4'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool'
      }
    ],
    stateMutability: 'pure'
  },
  {
    type: 'function',
    name: 'domainSeparator',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'eip712Domain',
    inputs: [],
    outputs: [
      {
        name: 'fields',
        type: 'bytes1',
        internalType: 'bytes1'
      },
      {
        name: 'name',
        type: 'string',
        internalType: 'string'
      },
      {
        name: 'version',
        type: 'string',
        internalType: 'string'
      },
      {
        name: 'chainId',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'verifyingContract',
        type: 'address',
        internalType: 'address'
      },
      {
        name: 'salt',
        type: 'bytes32',
        internalType: 'bytes32'
      },
      {
        name: 'extensions',
        type: 'uint256[]',
        internalType: 'uint256[]'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'entryPoint',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'address',
        internalType: 'address'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'execute',
    inputs: [
      {
        name: 'target',
        type: 'address',
        internalType: 'address'
      },
      {
        name: 'value',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'data',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    outputs: [],
    stateMutability: 'payable'
  },
  {
    type: 'function',
    name: 'executeBatch',
    inputs: [
      {
        name: 'calls',
        type: 'tuple[]',
        internalType: 'struct CoinbaseSmartWallet.Call[]',
        components: [
          {
            name: 'target',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'value',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'data',
            type: 'bytes',
            internalType: 'bytes'
          }
        ]
      }
    ],
    outputs: [],
    stateMutability: 'payable'
  },
  {
    type: 'function',
    name: 'executeWithoutChainIdValidation',
    inputs: [
      {
        name: 'calls',
        type: 'bytes[]',
        internalType: 'bytes[]'
      }
    ],
    outputs: [],
    stateMutability: 'payable'
  },
  {
    type: 'function',
    name: 'getUserOpHashWithoutChainId',
    inputs: [
      {
        name: 'userOp',
        type: 'tuple',
        internalType: 'struct UserOperation',
        components: [
          {
            name: 'sender',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'nonce',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'initCode',
            type: 'bytes',
            internalType: 'bytes'
          },
          {
            name: 'callData',
            type: 'bytes',
            internalType: 'bytes'
          },
          {
            name: 'callGasLimit',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'verificationGasLimit',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'preVerificationGas',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'maxFeePerGas',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'maxPriorityFeePerGas',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'paymasterAndData',
            type: 'bytes',
            internalType: 'bytes'
          },
          {
            name: 'signature',
            type: 'bytes',
            internalType: 'bytes'
          }
        ]
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'implementation',
    inputs: [],
    outputs: [
      {
        name: '$',
        type: 'address',
        internalType: 'address'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'initialize',
    inputs: [
      {
        name: 'owners',
        type: 'bytes[]',
        internalType: 'bytes[]'
      }
    ],
    outputs: [],
    stateMutability: 'payable'
  },
  {
    type: 'function',
    name: 'isOwnerAddress',
    inputs: [
      {
        name: 'account',
        type: 'address',
        internalType: 'address'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'isOwnerBytes',
    inputs: [
      {
        name: 'account',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'isOwnerPublicKey',
    inputs: [
      {
        name: 'x',
        type: 'bytes32',
        internalType: 'bytes32'
      },
      {
        name: 'y',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bool',
        internalType: 'bool'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'isValidSignature',
    inputs: [
      {
        name: 'hash',
        type: 'bytes32',
        internalType: 'bytes32'
      },
      {
        name: 'signature',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    outputs: [
      {
        name: 'result',
        type: 'bytes4',
        internalType: 'bytes4'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'nextOwnerIndex',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'ownerAtIndex',
    inputs: [
      {
        name: 'index',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'ownerCount',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'proxiableUUID',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'removeLastOwner',
    inputs: [
      {
        name: 'index',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'owner',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'removeOwnerAtIndex',
    inputs: [
      {
        name: 'index',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'owner',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    outputs: [],
    stateMutability: 'nonpayable'
  },
  {
    type: 'function',
    name: 'removedOwnersCount',
    inputs: [],
    outputs: [
      {
        name: '',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'replaySafeHash',
    inputs: [
      {
        name: 'hash',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    outputs: [
      {
        name: '',
        type: 'bytes32',
        internalType: 'bytes32'
      }
    ],
    stateMutability: 'view'
  },
  {
    type: 'function',
    name: 'upgradeToAndCall',
    inputs: [
      {
        name: 'newImplementation',
        type: 'address',
        internalType: 'address'
      },
      {
        name: 'data',
        type: 'bytes',
        internalType: 'bytes'
      }
    ],
    outputs: [],
    stateMutability: 'payable'
  },
  {
    type: 'function',
    name: 'validateUserOp',
    inputs: [
      {
        name: 'userOp',
        type: 'tuple',
        internalType: 'struct UserOperation',
        components: [
          {
            name: 'sender',
            type: 'address',
            internalType: 'address'
          },
          {
            name: 'nonce',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'initCode',
            type: 'bytes',
            internalType: 'bytes'
          },
          {
            name: 'callData',
            type: 'bytes',
            internalType: 'bytes'
          },
          {
            name: 'callGasLimit',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'verificationGasLimit',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'preVerificationGas',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'maxFeePerGas',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'maxPriorityFeePerGas',
            type: 'uint256',
            internalType: 'uint256'
          },
          {
            name: 'paymasterAndData',
            type: 'bytes',
            internalType: 'bytes'
          },
          {
            name: 'signature',
            type: 'bytes',
            internalType: 'bytes'
          }
        ]
      },
      {
        name: 'userOpHash',
        type: 'bytes32',
        internalType: 'bytes32'
      },
      {
        name: 'missingAccountFunds',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    outputs: [
      {
        name: 'validationData',
        type: 'uint256',
        internalType: 'uint256'
      }
    ],
    stateMutability: 'nonpayable'
  },
  {
    type: 'event',
    name: 'AddOwner',
    inputs: [
      {
        name: 'index',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256'
      },
      {
        name: 'owner',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes'
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'RemoveOwner',
    inputs: [
      {
        name: 'index',
        type: 'uint256',
        indexed: true,
        internalType: 'uint256'
      },
      {
        name: 'owner',
        type: 'bytes',
        indexed: false,
        internalType: 'bytes'
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'Upgraded',
    inputs: [
      {
        name: 'implementation',
        type: 'address',
        indexed: true,
        internalType: 'address'
      }
    ],
    anonymous: false
  },
  {
    type: 'error',
    name: 'AlreadyOwner',
    inputs: [
      {
        name: 'owner',
        type: 'bytes',
        internalType: 'bytes'
      }
    ]
  },
  {
    type: 'error',
    name: 'Initialized',
    inputs: []
  },
  {
    type: 'error',
    name: 'InvalidEthereumAddressOwner',
    inputs: [
      {
        name: 'owner',
        type: 'bytes',
        internalType: 'bytes'
      }
    ]
  },
  {
    type: 'error',
    name: 'InvalidNonceKey',
    inputs: [
      {
        name: 'key',
        type: 'uint256',
        internalType: 'uint256'
      }
    ]
  },
  {
    type: 'error',
    name: 'InvalidOwnerBytesLength',
    inputs: [
      {
        name: 'owner',
        type: 'bytes',
        internalType: 'bytes'
      }
    ]
  },
  {
    type: 'error',
    name: 'LastOwner',
    inputs: []
  },
  {
    type: 'error',
    name: 'NoOwnerAtIndex',
    inputs: [
      {
        name: 'index',
        type: 'uint256',
        internalType: 'uint256'
      }
    ]
  },
  {
    type: 'error',
    name: 'NotLastOwner',
    inputs: [
      {
        name: 'ownersRemaining',
        type: 'uint256',
        internalType: 'uint256'
      }
    ]
  },
  {
    type: 'error',
    name: 'SelectorNotAllowed',
    inputs: [
      {
        name: 'selector',
        type: 'bytes4',
        internalType: 'bytes4'
      }
    ]
  },
  {
    type: 'error',
    name: 'Unauthorized',
    inputs: []
  },
  {
    type: 'error',
    name: 'UnauthorizedCallContext',
    inputs: []
  },
  {
    type: 'error',
    name: 'UpgradeFailed',
    inputs: []
  },
  {
    type: 'error',
    name: 'WrongOwnerAtIndex',
    inputs: [
      {
        name: 'index',
        type: 'uint256',
        internalType: 'uint256'
      },
      {
        name: 'expectedOwner',
        type: 'bytes',
        internalType: 'bytes'
      },
      {
        name: 'actualOwner',
        type: 'bytes',
        internalType: 'bytes'
      }
    ]
  }
] as const
