import { Address, Hex } from 'viem'
import { ExZeroUser } from '../client/types'

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth0` hook.
 */
export interface WalletState {
  error?: Error
  isPasskeyAvailable: boolean
  isAuthenticated: boolean
  isLoading: boolean
  user?: ExZeroUser
  history: null | WalletCoinHistory[]
  allHistory: null | WalletCoinHistory[]
  onetimeLockHistory: null | WalletOnetimeLockHistory[]
  nicknames: Record<string, string>
}

/**
 * The initial wallet state.
 */
export const initialWalletState: WalletState = {
  isPasskeyAvailable: false,
  isAuthenticated: false,
  isLoading: true,
  history: null,
  allHistory: null,
  onetimeLockHistory: null,
  nicknames: {}
}

export interface WalletCoinHistory {
  id: string
  movingType: string
  token: string
  sender: Address
  senderDisplayName: string
  recipient: Address
  recipientDisplayName: string
  amount: string
  metadata: string
  txHash: Hex
  createdAt: number
}

export interface WalletOnetimeLockHistory {
  id: string
  status: 'LIVE' | 'COMPLETED' | 'CANCELLED'
  token: string
  sender: Address
  senderDisplayName: string
  recipient?: Address
  recipientDisplayName?: string
  amount: string
  metadata: string
  txHash: Hex
  createdAt: number
  updatedAt: number
  secret?: Hex
  messageId?: string
}
