import { Hex } from 'viem'
import { readContract } from 'viem/actions'
import { useQuery } from '@tanstack/react-query'
import { ethClient } from '../utils/client'
import { ONETIME_LOCK_DISPATCHER_ADDRESS } from '../constants'
import { OnetimeLockRequestDispatcherAbi } from '../abis/OnetimeLockRequestDispatcher'

export enum RequestStatus {
  Empty,
  Pending,
  Completed,
  Cancelled
}

export function useRequestStatus(chainId: number, id: Hex) {
  const query = useQuery({
    queryKey: ['request', chainId, id],
    queryFn: async () => {
      const req = await readContract(ethClient, {
        address: ONETIME_LOCK_DISPATCHER_ADDRESS,
        abi: OnetimeLockRequestDispatcherAbi,
        functionName: 'pendingRequests',
        args: [id]
      })

      const status = req[6]

      if (status === 0) {
        return RequestStatus.Empty
      } else if (status === 1) {
        return RequestStatus.Pending
      } else if (status === 2) {
        return RequestStatus.Completed
      } else if (status === 3) {
        return RequestStatus.Cancelled
      }
    },
    enabled: true
  })

  return query
}
