import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react'
import { reducer } from './reducer'
import { ExZeroContext, ExZeroContextInterface } from './exzero-context'
import { initialWalletState } from './wallet-state'
import { ExZeroClient } from '../client/ExZeroClient'
import { createError } from './utils'
import { Address, Hex, getAddress } from 'viem'
import {
  GetLinkTransferResponse,
  TransferByLinkResponse
} from '../client/types'
import { useQueryClient } from '@tanstack/react-query'
import { CoinMovingHistoryEntity } from '../client/query/coin-moving'
import { OnetimeLockEntity } from '../client/query/onetime-lock'

interface ExZeroProviderOptions {
  chainId: number
  apiKey: string
  endpoint?: string
  children?: React.ReactNode
  context?: React.Context<ExZeroContextInterface>
}

export const ExZeroProvider = (opts: ExZeroProviderOptions): JSX.Element => {
  const { children, context = ExZeroContext } = opts

  const [client] = useState(
    () => new ExZeroClient(opts.chainId, opts.apiKey, opts.endpoint)
  )
  const [state, dispatch] = useReducer(reducer, initialWalletState)
  const didInitialize = useRef(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (didInitialize.current) {
      return
    }
    didInitialize.current = true
    ;(async (): Promise<void> => {
      dispatch({
        type: 'CHECK_PASSKEY_AVAILABLE',
        isPasskeyAvailable: await client.isPasskeyAvailable()
      })

      try {
        await client.load()

        const user = client.getUser()

        dispatch({ type: 'INITIALIZED', user })
      } catch (error) {
        dispatch({ type: 'ERROR', error: createError(error) })
      }
    })()
  }, [client])

  const loadHistory = useCallback(
    async (token: Address): Promise<CoinMovingHistoryEntity[]> => {
      const history = await client.getHistory(token)

      const senders = history.map(item => getAddress(item.sender))
      const recipients = history.map(item => getAddress(item.recipient))
      const addressList: Address[] = [...new Set(senders.concat(recipients))]

      const nicknames = await client.fetchNicknames(addressList)

      dispatch({ type: 'FETCH_NICKNAME', nicknames })

      dispatch({ type: 'UPDATE_HISTORY', history })

      return history
    },
    [client]
  )

  const loadAllHistory = useCallback(
    async (token: Address): Promise<CoinMovingHistoryEntity[]> => {
      const allHistory = await client.getAllHistory(token)

      const senders = allHistory.map(item => getAddress(item.sender))
      const recipients = allHistory.map(item => getAddress(item.recipient))
      const addressList: Address[] = [...new Set(senders.concat(recipients))]

      const nicknames = await client.fetchNicknames(addressList)

      dispatch({ type: 'FETCH_NICKNAME', nicknames })

      dispatch({ type: 'FETCH_ALL_HISTORY', allHistory })

      return allHistory
    },
    [client]
  )

  const loadOnetimeLockHistory = useCallback(
    async (token: Address): Promise<OnetimeLockEntity[]> => {
      const history = await client.getOnetimeLockHistory(token)

      const senders = history.map(item => getAddress(item.sender))
      const recipients = history
        .map(item => item.recipient)
        .filter(r => !!r)
        .map(item => getAddress(item!))
      const addressList: Address[] = [...new Set(senders.concat(recipients))]

      const nicknames = await client.fetchNicknames(addressList)

      dispatch({ type: 'FETCH_NICKNAME', nicknames })

      dispatch({ type: 'UPDATE_ONETIME_LOCK_HISTORY', history })

      return history
    },
    [client]
  )

  const loadNicknames = useCallback(
    async (ethAddressList: Address[]): Promise<void> => {
      const nicknames = await client.fetchNicknames(ethAddressList)

      console.log(nicknames)

      dispatch({ type: 'FETCH_NICKNAME', nicknames })
    },
    [client]
  )

  const createWallet = useCallback(
    async (projectName: string): Promise<void> => {
      dispatch({ type: 'REGISTER' })

      try {
        await client.createWallet(projectName)

        const user = client.getUser()

        dispatch({ type: 'INITIALIZED', user })
      } catch (e: any) {
        console.error(e)
        dispatch({ type: 'ERROR', error: new Error(e.message) })
      }
    },
    [client]
  )

  const recoverWallet = useCallback(async (): Promise<void> => {
    dispatch({ type: 'REGISTER' })

    try {
      await client.recoverWallet()

      const user = client.getUser()

      dispatch({ type: 'INITIALIZED', user })
    } catch (e: any) {
      console.error(e)
      dispatch({ type: 'ERROR', error: new Error(e.message) })
    }
  }, [client])

  const updateNickName = useCallback(
    async (nickname: string): Promise<void> => {
      await client.updateNickName(nickname)

      const user = client.getUser()

      if (user) {
        dispatch({ type: 'UPDATE_USER', user })
      }
    },
    [client]
  )

  const approve = useCallback(
    async (token: Address): Promise<void> => {
      await client.approve(token)

      await queryClient.invalidateQueries({
        queryKey: ['allowance', token, state.user?.address]
      })
    },
    [client, queryClient, state.user?.address]
  )

  const transfer = useCallback(
    async (
      token: Address,
      to: Address,
      amount: bigint,
      message?: { message: string; imageType: string }
    ): Promise<boolean> => {
      try {
        await client.transfer(token, to, amount, message)

        await queryClient.invalidateQueries({
          queryKey: ['balance', token, to]
        })

        await queryClient.invalidateQueries({
          queryKey: ['balance', token, state.user?.address]
        })

        return true
      } catch (e: any) {
        console.error(e)

        dispatch({ type: 'ERROR', error: new Error(e.message) })

        return false
      }
    },
    [client, queryClient, state.user?.address]
  )

  const executeOperation = useCallback(
    async (target: Address, callData: Hex): Promise<void> => {
      await client.executeOperation(target, callData)
    },
    [client]
  )

  const transferByLink = useCallback(
    async (
      token: Address,
      amount: bigint,
      metadata: any,
      expiration: number
    ): Promise<TransferByLinkResponse | null> => {
      try {
        return await client.transferByLink(token, amount, metadata, expiration)
      } catch (e: any) {
        console.error(e)

        dispatch({ type: 'ERROR', error: new Error(e.message) })

        return null
      }
    },
    [client]
  )

  const getLinkTransfer = useCallback(
    async (id: string): Promise<GetLinkTransferResponse | null> => {
      return await client.getLinkTransfer(id)
    },
    [client]
  )

  const receiveLinkTransfer = useCallback(
    async (
      linkTransfer: GetLinkTransferResponse,
      secret: Hex
    ): Promise<boolean> => {
      try {
        await client.receiveLinkTransfer(linkTransfer, secret)
      } catch (e: any) {
        console.error(e)

        dispatch({ type: 'ERROR', error: new Error(e.message) })

        return false
      }
      return true
    },
    [client]
  )

  const contextValue = useMemo<ExZeroContextInterface>(() => {
    return {
      ...state,
      createWallet,
      recoverWallet,
      updateNickName,
      executeOperation,
      transferByLink,
      getLinkTransfer,
      receiveLinkTransfer,
      approve,
      transfer,
      loadHistory,
      loadAllHistory,
      loadOnetimeLockHistory,
      loadNicknames
    }
  }, [
    state,
    createWallet,
    recoverWallet,
    updateNickName,
    executeOperation,
    transferByLink,
    getLinkTransfer,
    receiveLinkTransfer,
    approve,
    transfer,
    loadHistory,
    loadAllHistory,
    loadOnetimeLockHistory,
    loadNicknames
  ])

  return <context.Provider value={contextValue}> {children} </context.Provider>
}
