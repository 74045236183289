import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './App'
import './index.css'
import { Buffer } from 'buffer'
import { ExZeroProvider } from './exzero/react/exzero-provider'
import RegisterWallet from './components/RegisterWallet'

// @ts-expect-error avoid Buffer is not defined
window.Buffer = Buffer

const queryClient = new QueryClient()

const root = createRoot(document.getElementById('root')!) // eslint-disable-line

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ExZeroProvider
        chainId={421614}
        apiKey="pk_mjjmzjghwtdv5pilb6ujdrerhvxlfn4xofnfcr3j7llkbebx544a"
      >
        <RegisterWallet>
          <App />
        </RegisterWallet>
      </ExZeroProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
