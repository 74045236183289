import React, { useCallback, useEffect } from 'react'
import { PrimaryButton } from '../components/Button'
import { Address } from 'viem'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { SubTitle } from '../components/Title'
import { useBalance } from '../exzero/react/useBalance'
import { CoinBalance } from '../components/CoinBalance'
import useExZero from '../exzero/react/use-exzero'
import { AWC_ADDRESS } from '../constants'
import { splitAddress } from '../exzero/react/utils'
import dayjs from 'dayjs'
import awabarLogo from '../assets/home/kyoto_g_t.png'
import { toErrorMessage } from '../utils/error'

enum Status {
  NotReceived,
  Processing,
  Received
}

const StoreTransferView = () => {
  const [status, setStatus] = React.useState<Status>(Status.NotReceived)

  const params = new URLSearchParams(window.location.search)

  const balance = useBalance(AWC_ADDRESS)
  const { nicknames, error, transfer, loadNicknames } = useExZero()

  const recipient = params.get('r')

  useEffect(() => {
    if (recipient) {
      loadNicknames([recipient as Address])
    }
  }, [recipient, loadNicknames])

  const onTransferClicked = useCallback(async () => {
    if (!recipient) {
      throw new Error('Invalid request')
    }

    setStatus(Status.Processing)

    const isSucceeded = await transfer(
      AWC_ADDRESS,
      recipient as Address,
      BigInt(1)
    )

    if (isSucceeded) {
      setStatus(Status.Received)
    } else {
      setStatus(Status.NotReceived)
    }
  }, [transfer, setStatus, recipient])

  if (!balance.isSuccess) {
    return (
      <div className="m-2 flex justify-center items-center">
        <div className="mt-8">
          <LoadingIndicator />
        </div>
      </div>
    )
  }

  const isExceeded = 1 > Number(balance.data)

  const now = dayjs()

  if (status === Status.Received) {
    return (
      <div className="m-2 flex justify-center items-center">
        <div className="mt-12">
          <div className="text-base space-y-4">
            <div className="flex justify-center">
              <div className="text-center text-base">
                {recipient
                  ? nicknames[recipient]
                    ? nicknames[recipient]
                    : splitAddress(recipient)
                  : '...'}
              </div>
            </div>

            <div className="flex justify-center">
              <div className="text-center text-base">
                {now.format('YYYY年MM月DD日 HH時mm分ss秒')}
              </div>
            </div>

            <div className="flex justify-center">
              <div className="text-center text-2xl font-bold">1 awaCoin</div>
            </div>
            <div className="flex justify-center">
              <div className="w-40 p-2 rounded-2xl bg-green-700 text-white text-center text-base font-bold">
                送付完了
              </div>
            </div>

            <div className="flex justify-center pt-6">
              <img src={awabarLogo} className="w-[70%]" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="m-2 flex justify-center items-center">
      <div className="mt-8">
        <div className="text-base space-y-3">
          <div>
            <div className="flex justify-center">
              <SubTitle>
                {recipient && nicknames[recipient]
                  ? nicknames[recipient] + 'に'
                  : ''}
              </SubTitle>
            </div>
            <div className="flex justify-center">
              <SubTitle>1 awaCoinを送付します。</SubTitle>
            </div>
          </div>
          <div className="flex justify-center">
            <CoinBalance erc20Address={AWC_ADDRESS} unit={'awaCoin'} />
          </div>

          <div className="fixed bottom-10 z-999 left-0 w-full p-2">
            <div className="text-xs text-red-700">
              {error ? toErrorMessage(error) : null}
              {isExceeded ? '残高が足りません' : null}
            </div>

            <div className="space-y-3">
              <PrimaryButton
                disabled={isExceeded || status !== Status.NotReceived}
                onClick={onTransferClicked}
              >
                {status === Status.Processing ? (
                  <LoadingIndicator />
                ) : (
                  '送付する'
                )}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreTransferView
